const SugorokuIndexEnum = Object.freeze({
  REQUEST_FOR_QUOTATION: { name: "request_for_quotation", value: 10, title: "見積依頼"},
  QUOTATION: { name: "quotation", value: 20, title: "見積作成" },
  SEND_QUOTATION: { name: "send_quotation", value: 30, title: "見積送付" },
  PRICE_NEGOTIATION: { name: "price_negotiation", value: 40, title: "価格交渉" },
  CONTRACT_WRITING: { name: "contract_writing", value: 50, title: "契約書作成" },
  PREPARATION_OF_SAFETY_DOCUMENTS: { name: "preparation_of_safety_documents", value: 60, title: "安全書類の作成" },
  WASTE_REMOVAL_PLAN: { name: "waste_removal_plan", value: 61, title: "ゴミの搬出計画" },
  PRE_CONSTRUCTION_REVIEW_MEETING: { name: "pre_construction_review_meeting", value: 70, title: "施工前検討会" },
  PRE_CONSTRUCTION_MEETING: { name: "pre_construction_meeting", value: 80, title: "着工前会議" },
  OVERALL_PROCESS_MEETING: { name: "overall_process_meeting", value: 81, title: "全体工程会議" },
  BREAKING_GROUND: { name: "breaking_ground", value: 90, title: "着工" },
  WEEKLY_REPORT: { name: "weekly_report", value: 100, title: "週報作成" },
  VOLUME_ADJUSTMENT_BOOK: { name: "volume_adjustment_book", value: 101, title: "出来高調整簿" },
  INVOICING: { name: "invoicing", value: 110, title: "請求書作成" },
  CONFIRM_PAYMENT: { name: "confirm_payment", value: 120, title: "入金確認" },
  DONE: { name: "done", value: 130, title: "完了" }
});

const SafetyDocumentTypeEnum = Object.freeze({
  'safety_document_paper': { value: 0, title: '紙' },
  'safety_document_green_sight': { value: 1, title: 'グリーンサイト' }
});

const ConsignmentContractTypeEnum = Object.freeze({
  'consignment_contract_paper': { value: 0, title: '紙' },
  'consignment_contract_file': { value: 1, title: '電子' }
});

const ManifestTypeEnum = Object.freeze({
  'manifest_paper': { value: 0, title: '紙' },
  'manifest_file': { value: 1, title: '電子' }
});

const DeliveryMethodEnum = Object.freeze({
  'email': { value: 0, title: 'email' },
  'by_post': { value: 1, title: 'by post' }
});

const BusinessTypeEnum = Object.freeze({
  DISMANTLING: { name: 'dismantling', title: '解体' },
  INDUSTRIAL_WASTE: { name: 'industrial_waste', title: '産廃' },
  REAL_ESTATE: { name: 'real_estate', title: '不動産' }
});

const WasteSugorokuIndexEnum = Object.freeze({
  GOT_ORDER_LIST: { name: "got_order_list", value: 1010, title: "委託契約" },
  BRING_OUT: { name: "bring_out_the_industrial_wastes", value: 1020, title: "排出" },
  DELIVER_TO_INTERMEDIATE_FACILITY: { name: "deliver_wastes_intermediate_treatment_facility", value: 1030, title: "運搬" },
  INTERMEDIATE_FACILITY: { name: "intermediate_treatment_facility", value: 1040, title: "中間処理" },
  DELIVER_TO_FINAL_FACILITY: { name: "deliver_wastes_final_treatment_facility", value: 1050, title: "運搬" },
  FINAL_FACILITY: { name: "final_treatment_facility", value: 1060, title: "最終処分" },
  FINISH_MANIFEST: { name: "finish_to_process_the_manifest", value: 1070, title: "マニ完了" },
  FINISHED_BILL: { name: "finished_bill", value: 1080, title: "請求" },
  FINISHED_PAYMENT: { name: "finished_payment", value: 1090, title: "入金" },
  COMPLETE: { name: "complete", value: 1100, title: "完了" },
});

const OrderWasteType = Object.freeze({
  concrete: "コンクリートガラ",
  asphalt: "アスファルトガラ",
  bricks_and_stones: "その他がれき",
  glass_and_ceramics: "ガラス・陶磁器くず",
  types_of_plastic: "廃プラスチック類",
  metal: "金属くず",
  paper: "紙くず",
  wood_waste: "木くず",
  wood: "生木",
  tatami: "畳 (繊維くず)",
  plasterboard: "ボード (ジプトーン)",
  styrofoam_or_urethane: "スタイロ・ウレタン",
  waterproofing: "防水(天井)",
  mixed_waste_stable_type: "混合廃棄物(安定型)",
  mixed_waste_managed_type: "混合廃棄物(管理型)",
  types_of_debris: "がれき類（ケイカル）",
  plastic: "廃プラ（Pタイル）",
  ceramic: "ガラ陶（スレート）",
  managed_type: "管理型",
  amiang: "アスベストレベル１・２",
  fluorocarbon: "フロン",
  fire_extinguisher: "ハロン（消火器）",
  mercury: "水銀(蛍光灯)"
})

const EstimateStatusEnum = Object.freeze({
  no_approved: "未承認",
  request_to_check: "承認依頼",
  approved: "承認済",
  sent_to_client: "先方送付",
  client_accepted: "先方承認",
  feedback: "フィードバック",
  denied: "却下"
})

// const QuoteStatusEnum = Object.freeze({
//   initial: { title: "見積依頼", textColor: "#1890FF", bgColor: "#F3F8FD", value: "initial" },
//   sending: { title: "返事待ち", textColor: "#FEAB35", bgColor: "rgba(254, 171, 53, 0.15)", value: "sending" },
//   completed: { title: "見積送付", textColor: "#000000", bgColor: "rgba(28, 180, 3, 0.1)", value: "completed" },
// });

const QuoteStatusEnum = Object.freeze({
  new_request: { title: "新規相談", textColor: "#1890FF", bgColor: "#F3F8FD", value: "new_request" },
  in_progress: { title: "対応中", textColor: "#FEAB35", bgColor: "rgba(254, 171, 53, 0.15)", value: "in_progress" },
  handled: {title: "対応済み", textColor: "#000000", bgColor: "rgba(28, 180, 3, 0.1)", value: "handled" },
  completed: { title: "完了", textColor: "#1890FF", bgColor: "#F3F8FD", value: "completed" },
});


const QuoteTypeEnum = Object.freeze({
  dispenser_installation: { title: "給茶機設置", value: "dispenser_installation" },
  maintenance: { title: "メンテナンス", value: "maintenance" },
  temporary_staffing: { title: "人材派遣", value: "temporary_staffing" },
  business_request: { title: "業務依頼", value: "business_request" },
  other: { title: "その他", value: "other" },
});

export {
  SugorokuIndexEnum,
  SafetyDocumentTypeEnum,
  ConsignmentContractTypeEnum,
  ManifestTypeEnum,
  DeliveryMethodEnum,
  BusinessTypeEnum,
  WasteSugorokuIndexEnum,
  OrderWasteType,
  EstimateStatusEnum,
  QuoteStatusEnum,
  QuoteTypeEnum,
};
